import { readState } from "@/__main__/app-state.mjs";
import getData, { readData } from "@/__main__/get-data.mjs";
import TFTMatchModel from "@/data-models/tft-match.mjs";
import { isPBE } from "@/game-lol/utils/util.mjs";
import * as API from "@/game-tft/api.mjs";
import { isFutureSet, isRevivalSet } from "@/game-tft/constants.mjs";
import getStatFilters from "@/game-tft/get-stats-filters.mjs";
import { identifyComp } from "@/game-tft/PostMatchPerformance.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import fetchCompsClusterData from "@/game-tft/tft-fetch-comps-cluster.mjs";
import { fetchStaticDataV2 } from "@/game-tft/tft-fetch-static-data.mjs";
import { fixMatchId } from "@/game-tft/utils.mjs";

async function fetchData(params, searchParams) {
  const [platform, fullName, matchId, _tab] = params;
  const [_gameName, _tagLine] = fullName.split("-");

  const correctMatchId = fixMatchId(platform, matchId);
  const matchPath = ["tft", "matches_v2", correctMatchId];

  const cachedMatch = await readData(matchPath);
  const shouldRefetch =
    !new URLSearchParams(searchParams).has("postmatch") &&
    Boolean(cachedMatch?._isLocal);

  const match = await getData(
    API.getMatch(correctMatchId),
    TFTMatchModel,
    matchPath,
    {
      shouldFetchIfPathExists: shouldRefetch,
    },
  );

  const promises = [];
  if (match && !(match instanceof Error)) {
    const set = isPBE(platform)
      ? `set${match.info?.tft_set_number}`
      : StaticTFT.getMatchSetByDate(
          match.info.game_datetime,
          match.info.queue_id,
        );

    // const participant = match.info.participants.find((participant) => {
    //   return (
    //     participant.BLITZ_riot_account.account.game_name.toLowerCase() ===
    //     gameName.toLowerCase()
    //   );
    // });

    // const isPBE = isFutureSet(set);
    // const latestPatch = isPBE
    //   ? readState.tft.pbePatch
    //   : readState.tft.latestPatch;
    promises.push(fetchStaticDataV2(searchParams, set, { skipStats: true }));
    // if (tab === "performance") {
    //   promises.push(
    //     fetchCompRecommendationData(
    //       match,
    //       searchParams,
    //       participant,
    //       set,
    //       latestPatch.patch,
    //     ),
    //   );
    // }
  }

  await Promise.all(promises);
}

const _fetchCompRecommendationData = (
  match,
  searchParams,
  participant,
  set,
  latestPatch,
) => {
  const playerUnits = participant.units;
  if (!playerUnits) return;
  const defaultFilters = readState.tft.defaultFilters;
  const defaultMode = defaultFilters?.default_mode.mode;
  const defaultRank = defaultFilters?.modes_default_rank?.[defaultMode]?.rank;
  const compsFilters = getStatFilters(
    searchParams,
    "comps",
    isFutureSet(set),
    isRevivalSet(set),
    defaultMode,
    defaultRank,
  );
  const compsData = readState.tft.stats.comps[compsFilters]?.[set];
  const compStat = identifyComp(playerUnits, compsData);
  const clusterId = compStat?.cluster_id;
  if (!clusterId) return;
  const patch =
    match.info.game_version.match(/<Releases\/(.*?)>/i)?.[1] ||
    readState.lol.currentPatch?.short;
  const options = {
    clusterId: clusterId,
    set,
    patch: isLaterPatch(patch, latestPatch) ? latestPatch : patch,
    filters: `rank=PLATINUM+&region=WORLD&mode=RANKED&cluster_id=${clusterId}`,
    tab: "augments",
    isEarlyComp: false,
    page: "compDetails",
    options: {
      networkBackOffTime: 1000 * 60 * 60, // 1 hour
    },
  };
  return fetchCompsClusterData(options);
};

function isLaterPatch(patch1, patch2) {
  if (!patch1 || !patch2) return false;

  const [major1, minor1] = patch1.split(".").map(Number);
  const [major2, minor2] = patch2.split(".").map(Number);

  // Compare major versions first
  if (major1 > major2) return true;
  if (major1 < major2) return false;

  // If major versions are the same, compare minor versions
  return minor1 > minor2;
}

export default fetchData;
